<template>
<side-bar :background-color="sidebarBackground" short-title="SchoolDock" title="SchoolDock" v-if="accessDetails">
    <template slot="links">
        <sidebar-item :link="{name: 'Dashboard', icon: 'ni ni-tv-2 text-primary', path: '/dashboard'
            }" />
        <sidebar-item :link="{name: 'School', icon: 'ni ni-building text-blue', path: '/admin/school-registrations'}" />
        <sidebar-item :link="{name: 'School Subject', icon: 'ni ni-ruler-pencil text-blue', path: '/admin/school-subject-registrations'}" />
        <sidebar-item :link="{name: 'Staff', icon: 'fa fa-users text-blue', path: '/admin/staff-registration'}" v-if="accessDetails.isSuperAdmin==true" />        
        <sidebar-item :link="{name: 'SMS', icon: 'fa fa-envelope text-blue', path: '/admin/sms-subscriptions-setup'}" v-if="accessDetails.isSuperAdmin==true" />      
        <sidebar-item :link="{name: 'CBT', icon: 'ni ni-tv-2 text-blue', path: '/admin/eduschool-cbt-exam'}" v-if="accessDetails.isAffliateMarketer==false" />
        <sidebar-item :link="{name: 'News', icon: 'ni ni-world text-blue', path: '/admin/news-setup'}" v-if="accessDetails.isAffliateMarketer==false" />
        <sidebar-item :link="{name: 'Community', icon: 'fa fa-users text-blue', path: '/admin/community-setup'}" v-if="accessDetails.isAffliateMarketer==false" />
        <sidebar-item :link="{name: 'Policy', icon: 'ni ni-tag text-blue', path: '/admin/privacy-setup'}" v-if="accessDetails.isAffliateMarketer==false" />

    </template>
</side-bar>
</template>
<script>
export default {    
     data() {
      return {
        onloader: "none",
        accessDetails:null,
        sidebarBackground: 'vue' //vue|blue|orange|green|red|primary
      };
    },
    mounted() {
        this.accessDetails = this.$store.getters['auth/_user'];
    },
}
</script>
